<template>
  <div class="skeleton-box" v-loading="!isLogin">
    <VideoPolling v-if="isLogin" :isCustomization="true" />
  </div>
</template>

<script>
import VideoPolling from '@/views/VideoPolling'
export default {
  components: { VideoPolling },
  computed: {
    isLogin() {
      return this.$store.state.userInfo ? true : false;
    }
  },
  data() {
    return {

    }
  },
  created() {
    this.login();
  },
  methods: {
    /**
     * userName: 登录名
     * password: 登录密码
     * tenantId: 公司代码
     * verifyCode: 验证码
     * redirect: 目标路由地址
     */
    async login() {
      try {
        this.loginLoading = true;
        const params = {
          userName: '中国石油',
          password: '123456',
          tenantId: 'KEHU',
          code: "bysk2020",
        };
        this.$store.dispatch("foreignLogin", params);
      } catch (err) {
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.skeleton-box {
  height: 100%;
}
</style>